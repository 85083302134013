var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"notification-section"},[_c('div',{staticClass:"date-sec"},[_c('p',{staticClass:"text-center dayName"},[_vm._v(" "+_vm._s(_vm.$t(_vm.noteDate.day))+" "),(
            _vm.day_notes &&
            _vm.not_in_active_year == 0 &&
            _vm.confirmed == 1 &&
            _vm.vacation == 0
          )?_c('span',{staticClass:"dayNote"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"dayNoteIcon"},'v-icon',attrs,false),on),[_vm._v(_vm._s("speaker_notes"))])]}}],null,false,2675238174)},[_c('span',[_vm._v(_vm._s(_vm.day_notes))])])],1):_vm._e()]),_c('span',{staticClass:"prev"},[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){return _vm.nextPrevDay(_vm.noteDate.date, false)}}},[_vm._v("mdi-chevron-left")])],1),_c('v-text-field',{staticClass:"dayNotes",attrs:{"id":"note-date","autocomplete":"off","solo":"","dense":""},on:{"keydown":function($event){$event.preventDefault();}},model:{value:(_vm.noteDate.date),callback:function ($$v) {_vm.$set(_vm.noteDate, "date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"noteDate.date"}}),_c('span',{staticClass:"next"},[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){return _vm.nextPrevDay(_vm.noteDate.date, true)}}},[_vm._v("mdi-chevron-right")])],1),(_vm.classes.length > 1)?_c('div',{staticClass:"text-center"},[_c('v-select',{attrs:{"items":_vm.classes,"item-text":"class_name","item-value":"id","solo":""},on:{"change":function($event){return _vm.getNotesnpmForTeacher(_vm.noteDate.date, _vm.selectedClass)}},model:{value:(_vm.selectedClass),callback:function ($$v) {_vm.selectedClass=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedClass"}})],1):_vm._e()],1),(
        _vm.vacation == 1 ||
        _vm.confirmed == 0 ||
        _vm.not_in_active_year == 1 ||
        _vm.lessons_notes.length == 0
      )?_c('v-alert',{attrs:{"color":"#eaeefc"}},[_vm._v(" "+_vm._s(_vm.$t("no weekly plan added for this day"))+" ")]):_vm._e(),(
        _vm.not_in_active_year == 0 &&
        _vm.confirmed == 1 &&
        _vm.lessons_notes.length > 0 &&
        _vm.vacation == 0
      )?_c('div',_vm._l((_vm.lessons_notes),function(note,index){return _c('div',{key:index},[_c('div',{staticClass:"notification"},[_c('p',{staticClass:"notification-note main-color"},[_vm._v(_vm._s(note.subject))]),(
              !note.notes && !note.class_work_notes && !note.home_work_notes
            )?_c('v-alert',{attrs:{"color":"#eaeefc"}},[_vm._v(" "+_vm._s(_vm.$t("no weekly plan added for this lesson"))+" ")]):_vm._e(),(note.notes)?_c('h4',{attrs:{"id":"note"}},[_vm._v(_vm._s(_vm.$t("Notes")))]):_vm._e(),(note.notes)?_c('p',{staticClass:"title",staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(note.notes)+" ")]):_vm._e(),(note.class_work_notes)?_c('h4',{attrs:{"id":"classwork"}},[_vm._v(" "+_vm._s(_vm.$t("Classwork"))+" ")]):_vm._e(),(note.class_work_notes)?_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(note.class_work_notes)+" ")]):_vm._e(),(note.home_work_notes)?_c('h4',{attrs:{"id":"Homework"}},[_vm._v(" "+_vm._s(_vm.$t("Homework"))+" ")]):_vm._e(),(note.home_work_notes)?_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(note.home_work_notes)+" ")]):_vm._e()],1)])}),0):_vm._e()],1),_c('div',{staticClass:"notificationScroll"},[_c('div',{staticClass:"date-sec"},[_c('p',{staticClass:"text-center dayName"},[_vm._v(" "+_vm._s(_vm.noteDate.day)+" "),(
            _vm.day_notes &&
            _vm.not_in_active_year == 0 &&
            _vm.confirmed == 1 &&
            _vm.vacation == 0
          )?_c('span',{staticClass:"dayNote"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"dayNoteIcon"},'v-icon',attrs,false),on),[_vm._v(_vm._s("speaker_notes"))])]}}],null,false,2675238174)},[_c('span',[_vm._v(_vm._s(_vm.day_notes))])])],1):_vm._e()]),_c('span',{staticClass:"prev"},[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){return _vm.nextPrevDay(_vm.noteDate.date, false)}}},[_vm._v("mdi-chevron-left")])],1),_c('v-text-field',{staticClass:"dayNotes",attrs:{"id":"note-date","autocomplete":"off","solo":"","dense":""},on:{"keydown":function($event){$event.preventDefault();}},model:{value:(_vm.noteDate.date),callback:function ($$v) {_vm.$set(_vm.noteDate, "date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"noteDate.date"}}),_c('span',{staticClass:"next"},[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){return _vm.nextPrevDay(_vm.noteDate.date, true)}}},[_vm._v("mdi-chevron-right")])],1),(_vm.classes.length > 1)?_c('div',{staticClass:"text-center"},[_c('v-select',{attrs:{"items":_vm.classes,"item-text":"class_name","item-value":"id","solo":""},on:{"change":function($event){return _vm.getNotesDataForTeacher(_vm.noteDate.date, _vm.selectedClass)}},model:{value:(_vm.selectedClass),callback:function ($$v) {_vm.selectedClass=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"selectedClass"}})],1):_vm._e()],1),(
        _vm.vacation == 1 ||
        _vm.confirmed == 0 ||
        _vm.not_in_active_year == 1 ||
        _vm.lessons_notes.length == 0
      )?_c('v-alert',{attrs:{"color":"#eaeefc"}},[_vm._v(" "+_vm._s(_vm.$t("no weekly plan added for this day"))+" ")]):_vm._e(),(
        _vm.not_in_active_year == 0 &&
        _vm.confirmed == 1 &&
        _vm.lessons_notes.length > 0 &&
        _vm.vacation == 0
      )?_c('div',_vm._l((_vm.lessons_notes),function(note,index){return _c('div',{key:index,staticClass:"notifyItem"},[_c('div',{staticClass:"notification"},[_c('p',{staticClass:"notification-note main-color"},[_vm._v(_vm._s(note.subject))]),(
              !note.notes && !note.class_work_notes && !note.home_work_notes
            )?_c('v-alert',{attrs:{"color":"#eaeefc"}},[_vm._v(" "+_vm._s(_vm.$t("no weekly plan added for this lesson"))+" ")]):_vm._e(),(note.notes)?_c('h4',{attrs:{"id":"note"}},[_vm._v(_vm._s(_vm.$t("Notes")))]):_vm._e(),(note.notes)?_c('p',{staticClass:"title",staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(note.notes)+" ")]):_vm._e(),(note.class_work_notes)?_c('h4',{attrs:{"id":"classwork"}},[_vm._v(" "+_vm._s(_vm.$t("Classwork"))+" ")]):_vm._e(),(note.class_work_notes)?_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(note.class_work_notes)+" ")]):_vm._e(),(note.home_work_notes)?_c('h4',{attrs:{"id":"Homework"}},[_vm._v(" "+_vm._s(_vm.$t("Homework"))+" ")]):_vm._e(),(note.home_work_notes)?_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(note.home_work_notes)+" ")]):_vm._e()],1)])}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }