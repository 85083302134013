<template>
  <div>
    <div class="notification-section">
      <div class="date-sec">
        <p class="text-center dayName">
          {{ $t(noteDate.day) }}
          <span
            class="dayNote"
            v-if="
              day_notes &&
              not_in_active_year == 0 &&
              confirmed == 1 &&
              vacation == 0
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="dayNoteIcon">{{
                  "speaker_notes"
                }}</v-icon>
              </template>
              <span>{{ day_notes }}</span>
            </v-tooltip>
          </span>
        </p>
        <span class="prev">
          <v-icon large @click="nextPrevDay(noteDate.date, false)"
            >mdi-chevron-left</v-icon
          >
        </span>
        <v-text-field
          id="note-date"
          class="dayNotes"
          v-model.trim="noteDate.date"
          autocomplete="off"
          solo
          dense
          @keydown.prevent
        ></v-text-field>
        <span class="next">
          <v-icon large @click="nextPrevDay(noteDate.date, true)"
            >mdi-chevron-right</v-icon
          >
        </span>

        <div class="text-center" v-if="classes.length > 1">
          <v-select
            :items="classes"
            item-text="class_name"
            item-value="id"
            v-model.trim="selectedClass"
            @change="getNotesnpmForTeacher(noteDate.date, selectedClass)"
            solo
          >
          </v-select>
        </div>
      </div>

      <v-alert
        color="#eaeefc"
        v-if="
          vacation == 1 ||
          confirmed == 0 ||
          not_in_active_year == 1 ||
          lessons_notes.length == 0
        "
      >
        {{ $t("no weekly plan added for this day") }}
      </v-alert>
      <div
        v-if="
          not_in_active_year == 0 &&
          confirmed == 1 &&
          lessons_notes.length > 0 &&
          vacation == 0
        "
      >
        <div v-for="(note, index) in lessons_notes" :key="index">
          <div class="notification">
            <p class="notification-note main-color">{{ note.subject }}</p>
            <v-alert
              color="#eaeefc"
              v-if="
                !note.notes && !note.class_work_notes && !note.home_work_notes
              "
            >
              {{ $t("no weekly plan added for this lesson") }}
            </v-alert>
            <h4 id="note" v-if="note.notes">{{ $t("Notes") }}</h4>
            <p class="title" v-if="note.notes" style="white-space: normal">
              {{ note.notes }}
            </p>
            <h4 id="classwork" v-if="note.class_work_notes">
              {{ $t("Classwork") }}
            </h4>
            <p class="title" v-if="note.class_work_notes">
              {{ note.class_work_notes }}
            </p>
            <h4 id="Homework" v-if="note.home_work_notes">
              {{ $t("Homework") }}
            </h4>
            <p class="title" v-if="note.home_work_notes">
              {{ note.home_work_notes }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="notificationScroll">
      <div class="date-sec">
        <p class="text-center dayName">
          {{ noteDate.day }}
          <span
            class="dayNote"
            v-if="
              day_notes &&
              not_in_active_year == 0 &&
              confirmed == 1 &&
              vacation == 0
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="dayNoteIcon">{{
                  "speaker_notes"
                }}</v-icon>
              </template>
              <span>{{ day_notes }}</span>
            </v-tooltip>
          </span>
        </p>
        <span class="prev">
          <v-icon large @click="nextPrevDay(noteDate.date, false)"
            >mdi-chevron-left</v-icon
          >
        </span>
        <v-text-field
          id="note-date"
          class="dayNotes"
          v-model.trim="noteDate.date"
          autocomplete="off"
          solo
          dense
          @keydown.prevent
        ></v-text-field>
        <span class="next">
          <v-icon large @click="nextPrevDay(noteDate.date, true)"
            >mdi-chevron-right</v-icon
          >
        </span>

        <div class="text-center" v-if="classes.length > 1">
          <v-select
            :items="classes"
            item-text="class_name"
            item-value="id"
            v-model.trim="selectedClass"
            @change="getNotesDataForTeacher(noteDate.date, selectedClass)"
            solo
          >
          </v-select>
        </div>
      </div>
      <v-alert
        color="#eaeefc"
        v-if="
          vacation == 1 ||
          confirmed == 0 ||
          not_in_active_year == 1 ||
          lessons_notes.length == 0
        "
      >
        {{ $t("no weekly plan added for this day") }}
      </v-alert>
      <div
        v-if="
          not_in_active_year == 0 &&
          confirmed == 1 &&
          lessons_notes.length > 0 &&
          vacation == 0
        "
      >
        <div
          class="notifyItem"
          v-for="(note, index) in lessons_notes"
          :key="index"
        >
          <div class="notification">
            <p class="notification-note main-color">{{ note.subject }}</p>
            <v-alert
              color="#eaeefc"
              v-if="
                !note.notes && !note.class_work_notes && !note.home_work_notes
              "
            >
              {{ $t("no weekly plan added for this lesson") }}
            </v-alert>
            <h4 id="note" v-if="note.notes">{{ $t("Notes") }}</h4>
            <p class="title" v-if="note.notes" style="white-space: normal">
              {{ note.notes }}
            </p>
            <h4 id="classwork" v-if="note.class_work_notes">
              {{ $t("Classwork") }}
            </h4>
            <p class="title" v-if="note.class_work_notes">
              {{ note.class_work_notes }}
            </p>
            <h4 id="Homework" v-if="note.home_work_notes">
              {{ $t("Homework") }}
            </h4>
            <p class="title" v-if="note.home_work_notes">
              {{ note.home_work_notes }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DashboardMixin } from "../mixins/DashboardMixin";

import axios from "axios";
export default {
  mixins: [DashboardMixin],

  name: "TeacherDashboardNotification",
  data() {
    return {
      noteDate: {
        date: "",
        day: "",
      },

      day_notes: "",
      vacation: 0,
      not_in_active_year: 0,
      lessons_notes: [],
      classes: [],
      selectedClass: "",
      confirmed: 0,
      teacherDashboard: true,
    };
  },
  watch: {
    "noteDate.date": {
      handler() {
        this.getDayName();
        if (this.selectedClass) {
          this.getNotesDataForTeacher(this.noteDate.date, this.selectedClass);
        }
      },
    },
  },
  methods: {
    getTeacherClasses() {
      axios
        .get(this.getApiUrl + "/teachers/getTeacherClasses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.classes = response.data.data;

            this.selectedClass =
              response.data.data.length > 0 ? response.data.data[0].id : "";

            this.getNotesDataForTeacher(this.noteDate.date, this.selectedClass);
          }
        });
    },
  },
  mounted() {
    this.getTeacherClasses();
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_dashboard.scss";
</style>
