<template>
  <v-card max-width="100%" class="pa-4 employees">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <h3 class="main-color card-title">{{ $t("Achievements") }}</h3>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="py-0">
        <div class="d-flex">
          <v-select
            :label="$t('Year')"
            v-model="selectedYear"
            item-text="year"
            item-value="year"
            :items="years"
            class="px-2"
            @change="getChartData()"
            solo
            dense
          ></v-select>

          <v-select
            :label="$t('Month')"
            v-model="selectedMonth"
            :items="months"
            item-text="month"
            item-value="id"
            class="px-2"
            @change="getChartData()"
            dense
            solo
          ></v-select>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <canvas id="student-doughnut-chart" height="200px"></canvas
      ></v-col>
      <v-col cols="12" sm="12" md="6">
        <div class="d-flex align-start">
          <v-icon color="#7ee9da" class="dashboard-blue iconChart" small
            >trip_origin</v-icon
          >
          <span class="val mr-1"
            >{{ $t("Lessons") }}
            <span class="counterStyle">{{ genderCounts.lessons }}</span></span
          >
        </div>
        <div class="d-flex align-start">
          <v-icon color="#a2a0fb" class="dashboard-blue iconChart" small
            >trip_origin</v-icon
          >
          <span class="val mr-1"
            >{{ $t("Exams") }}
            <span class="counterStyle">{{ genderCounts.exams }}</span></span
          >
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Chart from "chart.js";
import axios from "axios";

export default {
  name: "doughnutChartStudentsGender",
  props: ["classes"],
  data() {
    return {
      // chart data
      labels: [this.$i18n.t("Lessons"), this.$i18n.t("Exams")],
      months: [],
      years: [],
      selecteClass: "",
      genderCounts: [],
      studentChart: "",
      selectedYear: new Date().getFullYear().toString(),
      selectedMonth: new Date().getMonth() + 1,
    };
  },
  methods: {
    getChartData() {
      axios
        .get(
          this.getApiUrl +
            "/teachers/teacherAchievements?year=" +
            this.selectedYear +
            "&month=" +
            this.selectedMonth,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.genderCounts = response.data.data;
          this.createPiaChart();
        });
    },

    createPiaChart() {
      if (this.studentChart) {
        this.studentChart.destroy();
      }
      var doughnutChartData = {
        labels: [this.$i18n.t("Lessons"), this.$i18n.t("Exams")],
        datasets: [
          {
            backgroundColor: ["#0fe7da", "#a3a0fb"],
            data: [this.genderCounts.lessons, this.genderCounts.exams],
            label: this.$i18n.t("Total Students"),
          },
        ],
      };
      var doughnutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 2000,
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
      };

      const myChart = document
        .getElementById("student-doughnut-chart")
        .getContext("2d");

      // eslint-disable-next-line no-unused-vars
      var _this = this;
      this.studentChart = new Chart(myChart, {
        type: "doughnut",
        data: doughnutChartData,
        options: doughnutChartOptions,
        plugins: [
          {
            beforeDraw: function (chart) {
              var width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;

              ctx.restore();
              var fontSize = (height / 300).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.textBaseline = "middle";

              var text =
                  _this.months.length > 0
                    ? _this.months[_this.selectedMonth - 1].month +
                      " " +
                      _this.selectedYear
                    : "",
                textX =
                  this.currentAppLocale == "en" ||
                  this.currentAppLocale == undefined
                    ? Math.round((width - ctx.measureText(text).width) / 2)
                    : Math.round((width - ctx.measureText(text).width) / 1.65),
                textY = height / 2;

              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ],
      });
    },
    textCenter(val) {
      Chart.pluginService.register({
        beforeDraw: function (chart) {
          var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

          ctx.restore();
          var fontSize = "14px";
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";

          var text = val,
            textX =
              this.currentAppLocale == "en" ||
              this.currentAppLocale == undefined
                ? Math.round((width - ctx.measureText(text).width) / 2)
                : Math.round((width - ctx.measureText(text).width) / 1.65),
            textY = height / 2;

          ctx.fillText(text, textX, textY);
          ctx.save();
        },
      });
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/hrConfigration/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
        });
    },

    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.months = response.data.data;
        });
    },
  },
  mounted() {
    this.getChartData();
    this.getYears();
    this.getMonths();
    // this.getTeacherClasses();
  },
};
</script>

<style lang="scss" scoped>
.chart-info {
  .val {
    font-size: 14px !important;
  }
  .num {
    font-size: 14px !important;
  }
}

.employees,
.salaries,
.leave-request,
.leave-stats,
.allowances,
.employee-holidays {
  height: 100% !important;
}

.counterStyle {
  color: #bbb;
  padding: 0px 0px 0px 50px;
}
.iconChart {
  margin-top: 5px;
  margin-right: 4px;
}

@media (max-width: 600px) {
  #student-doughnut-chart {
    width: 180px !important;
    height: 180px !important;
  }
}
</style>
