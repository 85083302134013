<template>
  <div>
    <div class="hrRequestsBox">
      <p>{{ $t("HR Request") }}</p>
      <router-link to="/LeaveRequest">
        <div class="hrRows vacations">
          <v-row>
            <v-col cols="12" sm="9" style="padding: 11px 0px;">
              <span> {{ $t("Vacations") }}</span>
            </v-col>
            <v-col cols="12" sm="3">
              <span> {{ hrData.vacation_count }} </span>
            </v-col>
          </v-row>
        </div>
      </router-link>
      <router-link to="/LeaveRequest">
        <div class="hrRows leaves">
          <v-row>
            <v-col cols="12" sm="9">
              <span> {{ $t("Leaves") }}</span>
            </v-col>
            <v-col cols="12" sm="3">
              <span> {{ hrData.permission_count }} </span>
            </v-col>
          </v-row>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TeacherHrRequests",
  data() {
    return {
      hrData: {}
    };
  },
  methods: {
    getHrVacationLeavesData() {
      axios
        .get(this.getApiUrl + "/teachers/getTeacherRequestsCount/", {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          this.hrData = response.data.data;
        });
    }
  },
  mounted() {
    this.getHrVacationLeavesData();
  }
};
</script>

<style lang="scss">
.leaves {
  background-color: #55d8fe;
}
.vacations {
  background-color: #a3a0fb;
}
.hrRows {
  display: inline-block;
  width: 100%;
  border-radius: 7px;
  color: #fff;
  -webkit-box-shadow: 0 1px 4px #b4afaf;
  box-shadow: 0 1px 4px #b4afaf;
  margin: 10px 0px;
  padding: 0px 10px;
  font-size: 13px;
}
.hrRequestsBox {
  box-shadow: 0 1px 4px #b4afaf;
  padding: 1rem;
  margin: 1rem 0rem;
  p {
    margin-bottom: 0px;
  }
}
</style>
