<template>
  <div class="mt-8">
    <div>
      <v-row class="dachboardCard">
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-row class="assignCard assigment-color">
            <v-col cols="2" class="col-icon ">
              <router-link to="/homework">
                <v-icon class="iconsStyle">edit</v-icon>
              </router-link>
            </v-col>
            <v-col cols="8" class="py-3 px-0"
              ><router-link to="/homework">
                <p class="mb-0 white--text">{{ $t("Assignments") }}</p>

                <p
                  class="smallContent"
                  v-if="cardsData && cardsData.assignments_count > 0"
                >
                  {{ cardsData.assignments_count }}
                  {{ $t("Active Assignments") }}
                </p>
              </router-link>
            </v-col>
            <v-col cols="2" id="addAssignmentAction" class="actionButton">
              <v-btn
                icon
                v-if="permissions.add"
                @click="OpenDialogAddEdit('Add Assignment')"
                :title="$t('Add Assignment')"
              >
                <v-icon class="iconsStyle" color="white"> control_point</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4">
          <router-link to="/attendance">
            <v-row class="assignCard attendance-color">
              <v-col cols="2" class="col-icon "
                ><v-icon class="iconsStyle">school</v-icon>
              </v-col>
              <v-col cols="8" class="py-3 px-0">
                <p class="mb-0 white--text">{{ $t("Attendance") }}</p>

                <p
                  class="smallContent"
                  :class="
                    !cardsData || cardsData.classes_count < 0
                      ? 'hidden-content'
                      : ''
                  "
                >
                  {{ cardsData.classes_count }} {{ $t("Pending Lessons") }}
                </p>
              </v-col>
              <v-col cols="2" id="addAttendanceAction" class="actionButton "
                ><v-icon class="iconsStyle" :title="$t('Go to Attendance page')"
                  >control_point</v-icon
                ></v-col
              >
            </v-row>
          </router-link>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-row class="assignCard discussion-color">
            <v-col cols="2" class="col-icon ">
              <router-link to="/discussionrooms?page=1">
                <v-icon class="iconsStyle">menu_book</v-icon>
              </router-link>
            </v-col>
            <v-col cols="8" class="py-3 px-0">
              <router-link to="/discussionrooms?page=1">
                <p class="mb-0 white--text">{{ $t("Discussion Board") }}</p>

                <p
                  class="smallContent"
                  v-if="cardsData && cardsData.posts_count > 0"
                >
                  {{ cardsData.posts_count }} {{ $t("Posts Needed Apporve") }}
                </p>
              </router-link>
            </v-col>
            <v-col cols="2" id="addDiscussionAction" class="actionButton ">
              <v-btn
                icon
                @click="OpenDialogAddEdit('Add Discussion Room')"
                :title="$t('Add Discussion Room')"
              >
                <v-icon class="iconsStyle" color="#ffffff"
                  >control_point</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <AddEditHomeWorkComponent
      v-if="modal == 'Add Assignment'"
      :editedItem="editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' Assignments'"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="apiEndPoints"
      :teacher="teacher"
    ></AddEditHomeWorkComponent>

    <addEditDiscussionRoom
      v-if="modal == 'Add Discussion Room'"
      :editedItem="DiscussionEditedItem"
      :AddEditdialog="AddEditdialog"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="DiscussionApiEndPoints"
    ></addEditDiscussionRoom>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
import AddEditHomeWorkComponent from "@/components/modals/AddEditHomeWorkComponent";
import addEditDiscussionRoom from "@/components/modals/addEditDiscussionRoom";

export default {
  name: "TeacherDashboardCards",
  components: { AddEditHomeWorkComponent, addEditDiscussionRoom },

  data() {
    return {
      cardsData: {},
      teacher: ACL.Role("Teacher"),
      modal: "",
      AddEditdialog: false,
      editedIndex: -1,
      apiEndPoints: {
        list: "homework",
        create: "homework/store",
        edit: "homework/updateAssignment",
        delete: "accounts/destroy",
      },
      formTitle: "",
      editedItem: {
        id: "",
        title: "",
        mark: "",
        academic_grade_year_id: "",
        academic_grade_subjects_id: "",
        class_ids: [],
        teacher_id: "",
        publish_date: "",
        due_date: "",
        auto_correction: "",
        randomiz: "",
        allow_multi_submissions: "",
        email_notification_reminder: true,
        email_notification_comments: "",
      },

      DiscussionEditedItem: {
        name: "",
        about: "",
        valid_unitll: "",
        class_id: [],
        need_approve: false,
        replay_need_approve: false,
        date: "",
        modirators: [],
      },

      DiscussionApiEndPoints: {
        list: "discussionboard",
        // show: "discussionboard/show",
        create: "discussionboard/store",
        edit: "discussionboard/update",
        delete: "discussionboard/destroy",
      },

      permissions: {
        add: ACL.checkPermission("add-home-work"),
      },
    };
  },
  methods: {
    getDashboardCardData() {
      axios
        .get(this.getApiUrl + "/teachers/teacherCardsStatistics/", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          console.log(response.data.data);
          this.cardsData = response.data.data;
        });
    },
    OpenDialogAddEdit(modal) {
      this.modal = modal;
      this.editedIndex = -1;
      // this.table.editedItem = {};
      if (this.editedItem.en) {
        Object.keys(this.editedItem.en).forEach(
          (k) => (this.editedItem.en[k] = "")
        );
        Object.keys(this.editedItem).forEach((k) => {
          if (k != "en" && k != "ar") this.editedItem[k] = "";
        });
      } else {
        Object.keys(this.editedItem).forEach((k) => (this.editedItem[k] = ""));
      }
      if (this.editedItem.ar) {
        Object.keys(this.editedItem.ar).forEach(
          (k) => (this.editedItem.ar[k] = "")
        );
      }
      if (this.modal == "Add Assignment") {
        this.editedItem.email_notification_reminder = true;
      }
      this.formTitle = "Add";
      this.AddEditdialog = true;
    },
    onChildClick(value, item, index) {
      this.AddEditdialog = value;
      if (item != null && index == -1) {
        this.items.push(item);
        this.totalItems = this.totalItems + 1;

        this.length = Math.ceil(this.totalItems / this.options.itemsPerPage);
        if (this.page < this.length) {
          this.page = this.length;
        }
      } else if (index > -1 && item != null) {
        Object.assign(this.items[index], item);
        if (value == false) {
          this.items = [];
          // this.$nextTick(function() {
          //   this.getAllData();
          // });
        }
      }
    },
  },
  mounted() {
    this.getDashboardCardData();
  },
};
</script>

<style lang="scss">
$font-Color-white: #fff;

.assigment-color {
  background-color: #5a86ff;
}
.attendance-color {
  background-color: #a3a0fb;
}
.discussion-color {
  background-color: #ffb400;
}
.assignCard {
  border-radius: 7px;
  color: $font-Color-white;
  box-shadow: 0px 1px 4px #b4afaf;
  .iconsStyle {
    color: $font-Color-white;
  }
  .actionButton {
    background-color: #00000038;
    border-radius: 0px 5px 5px 0px;
    display: flex;
    justify-content: center;
  }
  .smallContent {
    font-size: 9px;
    color: $font-Color-white;
    margin-bottom: 0;
  }
  .col-icon {
    display: flex;
    justify-content: center;
  }
}

.hidden-content {
  visibility: hidden;
}
</style>
